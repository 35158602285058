import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { get, isEqual } from 'lodash';
import { Check, Close, Error, Info, PersonAdd } from '@mui/icons-material';
import { ManagementTable, TableColumn } from '../../components/ManagementTable';
import { Link, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { usePeopleSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChangeField,
  selectDeletePeople,
  selectPeople,
} from './slice/selectors';
import { useDebounce } from 'utils/useDebounce';
import { useQuery } from 'utils/query';
import { useParams } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { FilterBar } from '../FilterBar';
import { selectAppSettings } from '../App/slice/selectors';
import { ActionsMenu } from '../../components/ActionsMenu';
import { addToPreviewPageActions } from '../AddToPreviewPage/slice';
import { emailPeopleActions } from '../EmailPeople/slice';
import { AddToPreviewPage } from '../AddToPreviewPage';
import { useConfirm } from '../../components/ConfirmDialog';
import { grey } from '@mui/material/colors';
import { useHasChanged } from '../../../utils/usePrevious';
import { EmailPeople } from '../EmailPeople';
import { PopoverButton } from '../../components/PopoverButton';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';

export function List() {
  const match = useParams();
  const { actions } = usePeopleSlice();
  const dispatch = useDispatch();
  const people = useSelector(selectPeople);
  const deletePeople = useSelector(selectDeletePeople);
  const changeField = useSelector(selectChangeField);
  const { search } = useLocation();
  const confirm = useConfirm();
  const settings = useSelector(selectAppSettings);
  const [selectedRows, setSelectedRows] = useState<Array<number | string>>([]);

  const [selectEntireResultSet, setSelectEntireResultSet] =
    useState<boolean>(false);

  const { query, setQuery } = useQuery();

  const [pageSize, setPageSize] = React.useState<number>(
    +(query.pageSize || 25),
  );
  const [page, setPage] = React.useState<number>(+(query.page || 1));
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(query.sort || undefined);

  const getFilters = () => query.filters;
  const [filters, setFilters] = React.useState(query.filters || {});

  React.useEffect(() => {
    if (!isEqual(getFilters(), filters)) setFilters(getFilters());
  }, [search]);

  const debouncedFilters = useDebounce(filters, 400);

  useEffectOnChange(() => {
    setFilters(query.filters || {});
  }, query.filters);

  useEffectOnChange(() => {
    setPage(+query.page || 1);
  }, query.page);

  React.useEffect(() => {
    setQuery('pageSize', pageSize);
  }, [pageSize]);

  React.useEffect(() => {
    setQuery('page', page);
  }, [page]);

  useEffectOnChange(() => {
    setQuery('filters', filters);
    // setPage(1);
  }, filters);

  React.useEffect(() => {
    setQuery('sort', sort);
  }, [sort]);

  React.useEffect(() => {
    loadPeople();
  }, [dispatch, debouncedFilters, page, pageSize]);

  const deletePeopleChanged = useHasChanged(deletePeople.loading);
  const changeFieldChanged = useHasChanged(changeField.loading);

  React.useEffect(() => {
    if (
      (deletePeopleChanged && !deletePeople.loading) ||
      (changeFieldChanged && !changeField.loading)
    ) {
      loadPeople();
    }
  });

  const totalSelected = selectEntireResultSet
    ? people.resultSet.count
    : selectedRows.length;

  const additionalColumns = useMemo(() => {
    const columns: Array<TableColumn> = [];

    [1, 2, 3].forEach(id => {
      const filterKey = `voice_service_${id}`;
      if ((filters || {})[filterKey]) {
        const service: any =
          settings.voice_services.find(s => s.id === filters[filterKey].id) ||
          {};
        columns.push({
          value: v => {
            const service = get(
              v,
              `metadata.voice_services.voice-service-${filters[filterKey].id}`,
              {},
            );
            let output = `${service.level || 'Not set'}`;

            if (service.has_rate && !v.tvg_rates) {
              output += ` / ${service.rate}`;
            }

            return output;
          },
          label: `${service.name}`,
        });
      }
    });
    return columns;
  }, [filters, settings.voice_services]);

  const doAction = useCallback(
    action => {
      switch (action) {
        case 'add-to-preview-page':
          dispatch(addToPreviewPageActions.setOpen(true));
          break;
        case 'email-people':
          dispatch(emailPeopleActions.setOpen(true));
          break;
        case 'send-edit-invitation':
          confirm({
            title: `Send invitation to edit (${totalSelected} selected)`,
          })
            .then(() =>
              dispatch(
                actions.sendEditInvitationRequest(
                  selectEntireResultSet
                    ? [people.resultSet.key]
                    : (selectedRows as number[]),
                ),
              ),
            )
            .catch(() => {});
          break;
        case 'delete':
          confirm({
            title: `Delete (${totalSelected} selected)`,
          })
            .then(() =>
              dispatch(
                actions.deletePeopleRequest(
                  selectEntireResultSet
                    ? [people.resultSet.key]
                    : (selectedRows as number[]),
                ),
              ),
            )
            .catch(() => {});
          break;
        case 'add_to_website':
          confirm({
            title: `Set ${totalSelected} selected rows to appear on website searches`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  (selectEntireResultSet
                    ? [people.resultSet.key]
                    : selectedRows
                  ).map(id => ({
                    field_name: 'on_website',
                    field_value: true,
                    id: id as number | string,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
        case 'remove_from_website':
          confirm({
            title: `Hide ${totalSelected} selected rows from appearing on website searches`,
          })
            .then(() =>
              dispatch(
                actions.changeFieldRequest(
                  (selectEntireResultSet
                    ? [people.resultSet.key]
                    : selectedRows
                  ).map(id => ({
                    field_name: 'on_website',
                    field_value: false,
                    id: id as number | string,
                  })),
                ),
              ),
            )
            .catch(() => {});
          break;
      }
    },
    [selectedRows, selectEntireResultSet],
  );

  const loadPeople = () => {
    dispatch(
      actions.loadPeopleRequest({
        ...debouncedFilters,
        'page[number]': Math.max(page, 1),
        'page[size]': pageSize,
        sort: sort,
      }),
    );
  };

  return (
    <Box sx={{ my: 4 }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Grid item>
            <Typography variant="h3" color={'primary'}>
              All people
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <Button
              size={'large'}
              startIcon={<PersonAdd color={'secondary'} />}
              component={Link}
              to={`./new`}
            >
              Add new person
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Paper sx={{ my: 2 }}>
          <Box sx={{ px: 2, py: 1, backgroundColor: grey.A200 }}>
            <FilterBar
              fields={[
                {
                  name: 'searchTerm',
                  props: { label: 'Search people', width: 4 },
                },
                {
                  name: 'select',
                  props: {
                    label: 'Update status',
                    fieldName: 'update_status',
                    defaultValue: 'any',
                    width: 2,
                    options: [
                      { value: 'any', label: 'Any' },
                      { value: 'true', label: 'Awaiting data update' },
                      { value: 'false', label: 'Not sent update invitation' },
                    ],
                  },
                },
                {
                  name: 'checkbox',
                  props: {
                    label: 'Submitted data update',
                    fieldName: 'has_data_update',
                    width: 2,
                  },
                },
                {
                  name: 'select',
                  props: {
                    label: 'Website status',
                    fieldName: 'on_website_status',
                    defaultValue: 'any',
                    width: 2,
                    options: [
                      { value: 'any', label: 'Any' },
                      { value: 'true', label: 'On website' },
                      { value: 'false', label: 'Not on website' },
                    ],
                  },
                },
                {
                  name: 'select',
                  props: {
                    width: 2,
                    label: 'Active status',
                    fieldName: 'active_status',
                    defaultValue: 'only_active',
                    options: [
                      { value: 'only_active', label: 'Only Active' },
                      { value: 'show_inactive', label: 'Inactive only' },
                      { value: 'show_all', label: 'Show all' },
                    ],
                  },
                },
                {
                  name: 'divider',
                  props: {},
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Native Language',
                    vocabulary: settings.vocabularies.find(v => v.id === 5),
                    width: 3,
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Other Languages',
                    vocabulary: settings.vocabularies.find(v => v.id === 32),
                    width: 3,
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Native Accent',
                    vocabulary: settings.vocabularies.find(v => v.id === 11),
                    width: 3,
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Other Accents',
                    vocabulary: settings.vocabularies.find(v => v.id === 41),
                    width: 3,
                  },
                },
                {
                  name: 'divider',
                  props: {},
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Studio setup',
                    vocabulary: settings.vocabularies.find(v => v.id === 6),
                    width: 3,
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Artist type',
                    vocabulary: settings.vocabularies.find(v => v.id === 27),
                    width: 3,
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Superpower(s)',
                    vocabulary: settings.vocabularies.find(v => v.id === 42),
                    width: 3,
                  },
                },
                {
                  name: 'checkbox',
                  props: {
                    label: 'TVG Kids?',
                    fieldName: 'is_child',
                    width: 3,
                  },
                },
                {
                  name: 'divider',
                  props: {},
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Ethnicity',
                    vocabulary: settings.vocabularies.find(v => v.id === 40),
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Voice Type',
                    vocabulary: settings.vocabularies.find(v => v.id === 39),
                  },
                },
                {
                  name: 'terms',
                  props: {
                    label: 'Voice Range',
                    vocabulary: settings.vocabularies.find(v => v.id === 29),
                  },
                },
                {
                  name: 'divider',
                  props: {},
                },
                {
                  name: 'checkbox',
                  props: {
                    label: 'TVG Rates?',
                    fieldName: 'tvg_rates',
                    width: 3,
                  },
                },

                {
                  name: 'attributeValue',
                  props: {
                    label: 'Service 1',
                    fieldName: 'voice_service_1',
                    width: 3,
                    attributeOptions: settings.voice_services.map(vs => ({
                      id: vs.id,
                      label: vs.name,
                    })),
                    valueName: 'level',
                    valueOptions: ['Experienced', 'Very experienced'],
                  },
                },
                {
                  name: 'attributeValue',
                  props: {
                    label: 'Service 2',
                    fieldName: 'voice_service_2',
                    width: 3,
                    attributeOptions: settings.voice_services.map(vs => ({
                      id: vs.id,
                      label: vs.name,
                    })),
                    valueName: 'level',
                    valueOptions: ['Experienced', 'Very experienced'],
                  },
                },
                {
                  name: 'attributeValue',
                  props: {
                    label: 'Service 3',
                    fieldName: 'voice_service_3',
                    width: 3,
                    attributeOptions: settings.voice_services.map(vs => ({
                      id: vs.id,
                      label: vs.name,
                    })),
                    valueName: 'level',
                    valueOptions: ['Experienced', 'Very experienced'],
                  },
                },
              ]}
            />
          </Box>

          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  divider={<Divider flexItem orientation={'vertical'} />}
                >
                  <Button
                    disabled={
                      selectedRows.length === people.data.length &&
                      !selectEntireResultSet
                    }
                    onClick={() => {
                      setSelectEntireResultSet(false);
                      setSelectedRows(people.data.map(p => p.id as number));
                    }}
                  >
                    Select all on page
                  </Button>
                  <Button
                    disabled={selectEntireResultSet}
                    onClick={() => setSelectEntireResultSet(true)}
                  >
                    Select entire result set ({people.resultSet.count} items)
                  </Button>
                  <Button
                    disabled={!selectEntireResultSet && !selectedRows.length}
                    onClick={() => {
                      setSelectEntireResultSet(false);
                      setSelectedRows([]);
                    }}
                  >
                    Unselect all
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <ActionsMenu
                  label={`Bulk actions (${totalSelected} selected)`}
                  doAction={doAction}
                  disabled={!selectedRows.length && !selectEntireResultSet}
                  actions={[
                    {
                      type: 'add-to-preview-page',
                      label: 'Add to preview page',
                      disabled: selectEntireResultSet,
                    },
                    {
                      type: 'email-people',
                      label: 'Send email to selected',
                      disabled: selectEntireResultSet || totalSelected > 40,
                    },
                    {
                      type: 'send-edit-invitation',
                      label: 'Invite to edit details',
                    },
                    {
                      type: 'add_to_website',
                      label: 'Show on website',
                    },
                    {
                      type: 'remove_from_website',
                      label: 'Remove from website',
                    },
                    {
                      type: 'delete',
                      label: 'Delete permanently',
                      disabled: totalSelected > 20,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <ManagementTable
            enableCheckboxSelection={!selectEntireResultSet}
            onSelectionChange={ids => setSelectedRows(ids)}
            defaultRowsSelected={selectedRows}
            rows={people.data as any}
            columns={[
              {
                value: v => {
                  const nameText = `${v.first_name} ${v.last_name} ${
                    v.country_code ? `${`(${v.country_code})`}` : ''
                  }`;
                  return (
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                      <Box>{nameText}</Box>
                      {!!v.alert_text && (
                        <>
                          <PopoverButton
                            buttonIcon={<Info />}
                            buttonProps={{
                              color: 'error',
                            }}
                            popoverContent={
                              <Box sx={{ p: 4 }}>{v.alert_text}</Box>
                            }
                          />
                        </>
                      )}
                    </Stack>
                  );
                },
                label: 'Name',
              },
              {
                value: v =>
                  v.sample_sound_file_url ? (
                    <Box sx={{ display: 'inline-block' }}>
                      <audio
                        controls
                        src={v.sample_sound_file_url}
                        preload="none"
                      />
                    </Box>
                  ) : null,
                label: 'Sample',
              },
              {
                value: v => `${new Date(v.updated_at).toLocaleString()}`,
                label: 'Last updated',
              },
              {
                value: v =>
                  v.on_website ? (
                    <Check color={'success'} />
                  ) : (
                    <Close color={'error'} />
                  ),
                label: 'On website',
              },
              {
                value: v =>
                  v.tvg_rates ? (
                    <Check color={'success'} />
                  ) : (
                    <Close color={'error'} />
                  ),
                label: 'TVG Rates',
              },
              {
                value: v =>
                  v.is_child ? (
                    <Check color={'success'} />
                  ) : (
                    <Close color={'error'} />
                  ),
                label: 'TVG Kids',
              },
              {
                value: v => {
                  if (v.dob) {
                    const dob = new Date(v.dob);
                    const ageDifMs = Date.now() - dob.getTime();
                    const ageDate = new Date(ageDifMs);
                    return Math.abs(ageDate.getUTCFullYear() - 1970);
                  } else {
                    return '';
                  }
                },
                label: 'Age',
              },
              ...additionalColumns,
              {
                classes: 'align-right',
                value: v => {
                  return (
                    <Button
                      sx={{
                        my: 1,
                        whiteSpace: 'no-wrap',
                        wordWrap: 'normal',
                      }}
                      component={Link}
                      to={`./${v.id}`}
                      variant={'contained'}
                      color={'primary'}
                    >
                      <Badge
                        variant={'dot'}
                        color={
                          v.edit_invitation_status === 5 ? 'error' : 'success'
                        }
                        invisible={!v.edit_invitation_status}
                      >
                        Manage
                      </Badge>
                    </Button>
                  );
                },
              },
            ]}
            onClick={() => {}}
            page={page - 1}
            onChangePage={page => {
              setPage(page);
            }}
            onChangeRowsPerPage={size => setPageSize(size)}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            count={get(people, 'meta.total', 0)}
            loading={people.loading}
            loadingComponent={
              <LoadingIndicator minHeight={300} message={'Loading data'} />
            }
          />
        </Paper>
      </Container>
      <AddToPreviewPage ids={selectedRows} />
      <EmailPeople ids={selectedRows} />
    </Box>
  );
}
